// @nebular theming framework
@import "~@nebular/theme/styles/theming";
// @nebular out of the box themes
@import "~@nebular/theme/styles/themes";
@import "./mixins.scss";

@font-face {
  font-family: "Archivo";
  src: url("../../../assets/fonts/Archivo-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: url("../../../assets/fonts/Archivo-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Archivo";
  src: url("../../../assets/fonts/Archivo-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: url("../../../assets/fonts/ArchivoNarrow-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: url("../../../assets/fonts/ArchivoNarrow-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Narrow";
  src: url("../../../assets/fonts/ArchivoNarrow-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Archivo Black";
  src: url("../../../assets/fonts/ArchivoBlack-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

$nb-themes: nb-register-theme(
  (
    header-height: 3.75rem,
    font-family-primary: var(--font-body, Archivo, "Open Sans", "sans-serif"),
    font-family-secondary: var(--font-body, 'Archivo Narrow', "Open Sans", "sans-serif"),
    nav-box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%),
    color-primary-100: #ccf1ff,
    color-primary-200: #99deff,
    color-primary-300: #66c5ff,
    color-primary-400: #3fadff,
    color-primary-500: #0085ff,
    color-primary-600: #0066db,
    color-primary-700: #004cb7,
    color-primary-800: #003693,
    color-primary-900: #00267a,
    color-primary-transparent-100: rgba(0, 133, 255, 0.08),
    color-primary-transparent-200: rgba(0, 133, 255, 0.16),
    color-primary-transparent-300: rgba(0, 133, 255, 0.24),
    color-primary-transparent-400: rgba(0, 133, 255, 0.32),
    color-primary-transparent-500: rgba(0, 133, 255, 0.4),
    color-primary-transparent-600: rgba(0, 133, 255, 0.48),
    color-success-100: #d4fbcd,
    color-success-200: #a2f89c,
    color-success-300: #68ea6b,
    color-success-400: #41d653,
    color-success-500: #0ebc34,
    color-success-600: #0aa139,
    color-success-700: #07873b,
    color-success-800: #046d39,
    color-success-900: #025a37,
    color-success-transparent-100: rgba(14, 188, 52, 0.08),
    color-success-transparent-200: rgba(14, 188, 52, 0.16),
    color-success-transparent-300: rgba(14, 188, 52, 0.24),
    color-success-transparent-400: rgba(14, 188, 52, 0.32),
    color-success-transparent-500: rgba(14, 188, 52, 0.4),
    color-success-transparent-600: rgba(14, 188, 52, 0.48),
    color-info-100: #d9f1fe,
    color-info-200: #b3e0fe,
    color-info-300: #8cccfe,
    color-info-400: #70b8fd,
    color-info-500: #4198fc,
    color-info-600: #2f76d8,
    color-info-700: #2058b5,
    color-info-800: #143d92,
    color-info-900: #0c2a78,
    color-info-transparent-100: rgba(65, 152, 252, 0.08),
    color-info-transparent-200: rgba(65, 152, 252, 0.16),
    color-info-transparent-300: rgba(65, 152, 252, 0.24),
    color-info-transparent-400: rgba(65, 152, 252, 0.32),
    color-info-transparent-500: rgba(65, 152, 252, 0.4),
    color-info-transparent-600: rgba(65, 152, 252, 0.48),
    color-warning-100: #fff6da,
    color-warning-200: #ffebb5,
    color-warning-300: #ffdd90,
    color-warning-400: #ffcf75,
    color-warning-500: #ffb847,
    color-warning-600: #db9433,
    color-warning-700: #b77223,
    color-warning-800: #935416,
    color-warning-900: #7a3f0d,
    color-warning-transparent-100: rgba(255, 184, 71, 0.08),
    color-warning-transparent-200: rgba(255, 184, 71, 0.16),
    color-warning-transparent-300: rgba(255, 184, 71, 0.24),
    color-warning-transparent-400: rgba(255, 184, 71, 0.32),
    color-warning-transparent-500: rgba(255, 184, 71, 0.4),
    color-warning-transparent-600: rgba(255, 184, 71, 0.48),
    color-danger-100: #ffe5d6,
    color-danger-200: #ffc5ae,
    color-danger-300: #ff9e85,
    color-danger-400: #ff7967,
    color-danger-500: #ff3c35,
    color-danger-600: #db262f,
    color-danger-700: #b71a2f,
    color-danger-800: #93102d,
    color-danger-900: #7a0a2c,
    color-danger-transparent-100: rgba(255, 60, 53, 0.08),
    color-danger-transparent-200: rgba(255, 60, 53, 0.16),
    color-danger-transparent-300: rgba(255, 60, 53, 0.24),
    color-danger-transparent-400: rgba(255, 60, 53, 0.32),
    color-danger-transparent-500: rgba(255, 60, 53, 0.4),
    color-danger-transparent-600: rgba(255, 60, 53, 0.48),
    button-ghost-basic-text-color: color-basic-800,
    color-basic-500: #66717b,
    color-basic-550: #575757,
    menu-item-divider-style: none,
    menu-item-padding: 0.875rem 1.75rem,
    menu-text-font-family: font-family-primary,
    menu-text-font-weight: 500,
    menu-text-font-size: 1rem,
    menu-text-color: text-basic-color,
    input-basic-background-color: background-basic-color-1,
    border-alternative-color-3: #edf1f7,
    background-basic-color-4: #e4e9f2,
    color-subheading: #8f9bb3,
    field-border: #e4e9f2,
    field-background: #ffffff,
    color-light-background: #ffffff,
    color-grey-background: #eaeff5,
    sidebar-background: #f8fbff,
    divider-color: #d8dde7,
    background-divider-color: #E4E9F2,
    
    // Logos
    logo-svg-src: url("/assets/svg/RackCoach.svg"),
    logo-svg-src-android: url('/android_asset/www/assets/svg/RackCoach.svg'),
    logo-no-text-svg-src: url("/assets/svg/logo_black.svg"),
    logo-no-text-svg-src-android: url("/android_asset/www/assets/svg/logo_black.svg"),

    // Borders
    default-border-radius: 10px,
    default-border: 1px solid #e4e9f2,
    default-border-color: #e4e9f2,
    rounded-borders: 50px,
    card-border-radius: 0.625rem,
    border-radius: 0.625rem,

    // Colors
    glazier-green: #00dd20,
    rack-green: #5fe870,
    rack-blue: #0085ff,
    rack-red: #c94533,
    basic-text-color: #333333,
    nav-grey-color: #66717b,
    nav-background: #F8FBFF,
    basic-placeholder-text-color: #8E8E90,


    // Gradients
    success-gradient: linear-gradient(90deg, #20a632 0%, #5fe870 100%),
    primary-gradient:
      linear-gradient(90deg, darken(#0085ff, 15%) 0%, #0085ff 100%),
    danger-gradient:
      linear-gradient(90deg, darken(#c94533, 15%) 0%, #c94533 100%),

    color-basic-450: #333333,
    color-basic-600: #8b8b8b,
    color-basic-1000: #edf1f7,
    color-basic-focus-border: transparent,
    color-basic-focus: transparent,
    color-primary-transparent-focus: transparent,
    color-primary-transparent-focus-border: transparent,
    button-ghost-basic-focus-background-color: transparent,
    button-ghost-basic-focus-border-color: transparent,
    button-ghost-primary-focus-background-color: transparent,
    button-ghost-primary-focus-border-color: transparent,
    user-small-name-text-font-size: 0.938rem,
    user-name-text-font-weight: 600,
    comment-background-color: #ECF1F6,
    text-primary-hover-color: color-primary-500,
    input-giant-placeholder-text-font-size: 36px,
    input-giant-placeholder-text-font-weight: bold,
    input-info-placeholder-text-color: #d5dee5,
    input-giant-placeholder-text-line-height: 2.25rem,
    input-medium-text-font-weight: 400,
    input-large-text-font-weight: 400,
    route-tabset-tab-text-font-family: Archivo Narrow,
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #f7f9fc,
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    card-padding: 1rem,
    progress-bar-tiny-height: 5px,
    divider-color-alternate: #e4e9f2,
    text-light-color-2: #66717b,
    toggle-width: 60px,
    toggle-height: 30px,
    toggle-basic-focus-background-color: transparent,
    toggle-basic-focus-border-color: transparent,
    placeholder-text-color: rgb(143, 155, 179),
    input-placeholder-text-color: #8f9bb3,
    progress-bar-animation-duration: 200ms,
    card-alt-background-color: #eaeff5,
    gridster-grid-color: #e3e3e3
  ),
  default,
  default
);
$nb-themes: nb-register-theme(
  (
    header-height: 3.75rem,
    font-family-primary: var(--font-body, Archivo, "Open Sans", "sans-serif"),
    font-family-secondary: var(--font-body, 'Archivo Narrow', "Open Sans", "sans-serif"),
    nav-box-shadow: 0 -2px 10px rgba(0, 0, 0, 1),
    color-primary-100: #ccf1ff,
    color-primary-200: #99deff,
    color-primary-300: #66c5ff,
    color-primary-400: #3fadff,
    color-primary-500: #0085ff,
    color-primary-600: #0066db,
    color-primary-700: #004cb7,
    color-primary-800: #003693,
    color-primary-900: #00267a,
    color-primary-transparent-100: rgba(0, 133, 255, 0.08),
    color-primary-transparent-200: rgba(0, 133, 255, 0.16),
    color-primary-transparent-300: rgba(0, 133, 255, 0.24),
    color-primary-transparent-400: rgba(0, 133, 255, 0.32),
    color-primary-transparent-500: rgba(0, 133, 255, 0.4),
    color-primary-transparent-600: rgba(0, 133, 255, 0.48),
    color-success-100: #d4fbcd,
    color-success-200: #a2f89c,
    color-success-300: #68ea6b,
    color-success-400: #41d653,
    color-success-500: #0ebc34,
    color-success-600: #0aa139,
    color-success-700: #07873b,
    color-success-800: #046d39,
    color-success-900: #025a37,
    color-success-transparent-100: rgba(14, 188, 52, 0.08),
    color-success-transparent-200: rgba(14, 188, 52, 0.16),
    color-success-transparent-300: rgba(14, 188, 52, 0.24),
    color-success-transparent-400: rgba(14, 188, 52, 0.32),
    color-success-transparent-500: rgba(14, 188, 52, 0.4),
    color-success-transparent-600: rgba(14, 188, 52, 0.48),
    color-info-100: #d9f1fe,
    color-info-200: #b3e0fe,
    color-info-300: #8cccfe,
    color-info-400: #70b8fd,
    color-info-500: #4198fc,
    color-info-600: #2f76d8,
    color-info-700: #2058b5,
    color-info-800: #143d92,
    color-info-900: #0c2a78,
    color-info-transparent-100: rgba(65, 152, 252, 0.08),
    color-info-transparent-200: rgba(65, 152, 252, 0.16),
    color-info-transparent-300: rgba(65, 152, 252, 0.24),
    color-info-transparent-400: rgba(65, 152, 252, 0.32),
    color-info-transparent-500: rgba(65, 152, 252, 0.4),
    color-info-transparent-600: rgba(65, 152, 252, 0.48),
    color-warning-100: #fff6da,
    color-warning-200: #ffebb5,
    color-warning-300: #ffdd90,
    color-warning-400: #ffcf75,
    color-warning-500: #ffb847,
    color-warning-600: #db9433,
    color-warning-700: #b77223,
    color-warning-800: #935416,
    color-warning-900: #7a3f0d,
    color-warning-transparent-100: rgba(255, 184, 71, 0.08),
    color-warning-transparent-200: rgba(255, 184, 71, 0.16),
    color-warning-transparent-300: rgba(255, 184, 71, 0.24),
    color-warning-transparent-400: rgba(255, 184, 71, 0.32),
    color-warning-transparent-500: rgba(255, 184, 71, 0.4),
    color-warning-transparent-600: rgba(255, 184, 71, 0.48),
    color-danger-100: #ffe5d6,
    color-danger-200: #ffc5ae,
    color-danger-300: #ff9e85,
    color-danger-400: #ff7967,
    color-danger-500: #ff3c35,
    color-danger-600: #db262f,
    color-danger-700: #b71a2f,
    color-danger-800: #93102d,
    color-danger-900: #7a0a2c,
    color-danger-transparent-100: rgba(255, 60, 53, 0.08),
    color-danger-transparent-200: rgba(255, 60, 53, 0.16),
    color-danger-transparent-300: rgba(255, 60, 53, 0.24),
    color-danger-transparent-400: rgba(255, 60, 53, 0.32),
    color-danger-transparent-500: rgba(255, 60, 53, 0.4),
    color-danger-transparent-600: rgba(255, 60, 53, 0.48),
    color-basic-100: #d9d9d9,
    color-basic-200: #c3c3c3,
    color-basic-300: #aeaeae,
    color-basic-400: #989898,
    color-basic-450: #d9d9d9,
    color-basic-500: #8b8b8b,
    color-basic-550: #8b8b8b,
    color-basic-600: #6d6d6d,
    color-basic-700: #202326,
    color-basic-800: #15191c,
    color-basic-900: #0e1113,
    color-basic-1000: #070809,
    color-basic-1100: #000000,
    glazier-green: #00dd20,
    success-gradient: linear-gradient(90deg, #20a632 0%, #5fe870 100%),
    
    // Logos
    logo-svg-src: url("/assets/svg/RackCoach_Dark.svg"),
    logo-svg-src-android: url("/android_asset/www/assets/svg/RackCoach_Dark.svg"),
    logo-no-text-svg-src: url("/assets/svg/logo_white.svg"),
    logo-no-text-svg-src-android: url("/android_asset/www/assets/svg/logo_white.svg"),
    
    // Borders
    default-border-radius: 10px,
    default-border: 1px solid #292B36,
    default-border-color: #292B36,
    rounded-borders: 50px,
    card-border-radius: 0.625rem,
    border-radius: 0.625rem,
    
    // Colors
    rack-green: #5fe870,
    rack-blue: #0085FF,
    rack-red: #c94533,
    basic-text-color: #d9d9d9,
    nav-grey-color: #8b8b8b,
    nav-background: #0C0C17,
    basic-placeholder-text-color: #66717b,
    
    // Gradients
    primary-gradient:
      linear-gradient(90deg, darken(#0085ff, 15%) 0%, #0085ff 100%),
    danger-gradient:
      linear-gradient(90deg, darken(#c94533, 15%) 0%, #c94533 100%),

    input-basic-background-color: background-basic-color-1,
    color-subheading: #8f9bb3,
    field-border: #101426,
    field-background: #15191c,
    color-light-background: color-basic-800,
    color-grey-background: color-basic-800,
    sidebar-background: #101426,
    border-alternative-color-3: #313131,
    background-basic-color-4: #313131,
    divider-color: #3a3c41,
    background-divider-color: border-alternative-color-3,
    comment-background-color: #242628,
    input-large-placeholder-text-font-size: 36px,
    input-large-placeholder-text-font-weight: bold,
    input-info-placeholder-text-color: #d5dee5,
    input-large-placeholder-text-line-height: 2.25rem,
    input-giant-placeholder-text-font-size: 36px,
    input-giant-placeholder-text-font-weight: bold,
    input-giant-placeholder-text-line-height: 2.25rem,
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    menu-item-divider-style: none,
    menu-item-padding: 0.875rem 1.75rem,
    menu-text-font-family: font-family-primary,
    menu-text-font-weight: 500,
    menu-text-font-size: 1rem,
    menu-text-color: text-basic-color,
    card-padding: 1rem,
    progress-bar-tiny-height: 5px,
    divider-color-alternate: #313131,
    text-light-color-2: #8b8b8b,
    toggle-width: 60px,
    toggle-height: 30px,
    toggle-basic-focus-background-color: transparent,
    toggle-basic-focus-border-color: transparent,
    placeholder-text-color: rgb(217, 217, 217),
    input-placeholder-text-color: #6d6d6d,
    progress-bar-animation-duration: 200ms,
    card-alt-background-color: #333333,
    gridster-grid-color: #1a1a1a
  ),
  dark,
  dark
);
