/* Generated by Glyphter (http://www.glyphter.com) on  Tue Mar 22 2022*/
@font-face {
    font-family: 'RackIcons';
    src: url('../fonts/RackIcons.eot');
    src: url('../fonts/RackIcons.eot?#iefix') format('embedded-opentype'),
         url('../fonts/RackIcons.woff') format('woff'),
         url('../fonts/RackIcons.ttf') format('truetype'),
         url('../fonts/RackIcons.svg#RackIcons') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='ra-']:before{
	display: inline-block;
   font-family: 'RackIcons';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.ra-account-tree:before{content:'\0041';}
.ra-building-columns:before{content:'\0042';}
.ra-dumbbell:before{content:'\0043';}
.ra-filter:before{content:'\0044';}
.ra-fire:before{content:'\0045';}
.ra-grouper:before{content:'\0046';}
.ra-history:before{content:'\0047';}
.ra-key:before{content:'\0048';}
.ra-pause:before{content:'\0049';}
.ra-person-circle:before{content:'\004a';}
.ra-play:before{content:'\004b';}
.ra-plus-30-sec:before{content:'\004c';}
.ra-pulse:before{content:'\004d';}
.ra-remote:before{content:'\004e';}
.ra-tablet:before{content:'\004f';}
.ra-thumb-up-outline:before{content:'\0050';}
.ra-timer:before{content:'\0051';}
.ra-timer-2:before{content:'\0052';}