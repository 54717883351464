@import './themes';
@import "./mixins.scss";

@mixin nb-overrides() {
    nb-select.size-medium button {
        padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;
        nb-icon {
            right: 0.41rem !important;
        }
    }
    input.big-input {
        background-color: nb-theme(background-basic-color-1) !important;
        border: none !important;
        font-size: 36px !important;
    }
    input.big-input::placeholder,
    input.big-input #placeholder,
    input.big-input::-webkit-input-placeholder,
    input.big-input::-moz-placeholder,
    input.big-input:-ms-input-placeholder,
    input.big-input:-moz-placeholder,
    [nbInput].size-medium::placeholder {
        font-family: 'Archivo', 'Open Sans' !important;
        color: #D5DEE5 !important;
        font-weight: bold !important;
        font-size: 36px !important;
    }
    div.mat-form-field-infix {
        width: 100%;
    }
    input.mat-input-element {
        border: .0625rem solid nb-theme(field-border);
        border-radius: .25rem;
        font-size: .9375rem;
        font-weight: 400;
        line-height: 1.5rem;
        padding: .4375rem 1rem;
        background-color: nb-theme(field-background);
        color: nb-theme(text-basic-color);
    }
    mat-form-field {
        .mat-form-field-label-wrapper {
            padding-top: 11px;
            color: nb-theme(color-subheading);
            label {
                padding-left: calc(1rem + 1px);
            }
        }
    }
    mat-form-field.has-text {
        .mat-form-field-label-wrapper {
            display: none;
        }
    }
    .mat-autocomplete-panel {
        background-color: nb-theme(color-light-background);
        border: .0625rem solid nb-theme(field-border);
        border-radius: .25rem;
        box-shadow: none;
        overflow: auto;
    }
    .mat-option-text {
        display: flex;
        align-items: center;
    }
    mat-option,
    .mat-option-text {
        color: nb-theme(text-basic-color);
        font-weight: bold;
    }
    mat-option:hover {
        background-color: nb-theme(color-basic-disabled);
    }
    [nbButton].appearance-ghost:focus:not(:hover):not(:active) {
        box-shadow: none !important;
    }
    nb-spinner {
        background-color: transparent !important;
    }
    nb-accordion {
        box-shadow: none;
    }
    nb-spinner {
        .spin-circle {
            // border-top-color: nb-theme(rack-blue) !important;
            // border-right-color: transparent !important;
            // border-bottom-color: nb-theme(rack-blue) !important;
            // border-left-color: nb-theme(rack-blue) !important;
        }
    }
    nb-accordion-item,
    nb-accordion-item:first-child,
    nb-accordion-item:last-child {
        margin-bottom: 15px;
        border-radius: 10px;
        background-color: nb-theme(background-basic-color-1);
        font-family: 'Archivo', 'Open Sans';
        nb-accordion-item-header {
            height: 56px;
            color: nb-theme(color-primary-500);
            font-weight: 700;
            font-size: 16px;
            nb-icon:not(.gray-exempt) {
                color: nb-theme(color-basic-500);
            }
        }
        nb-accordion-item-body {
            padding-left: 16px;
            padding-right: 16px;
            .item-body {
                padding: 0;
            }
            .accordion-list-item {
                font-size: 16px;
                font-weight: 600;
                border-bottom: 1px solid nb-theme(border-alternative-color-3);
                display: flex;
                align-items: center;
                height: 47px;
            }
            .accordion-list-item:last-child {
                border-bottom: none;
            }
        }
    }
    nb-toastr-container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        margin: 0;
        padding-bottom: 20px;
        width: 100vw;
        height: auto;
        border-radius: 15px !important;
    }
    nav {
        box-shadow: nb-theme(nav-box-shadow) !important;
    }
    .list-item {
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid nb-theme(border-alternative-color-3);
        display: flex;
        align-items: center;
        height: 47px;
    }
    .list-item:last-child {
        border-bottom: none;
    }
    .cdk-overlay-pane {
        max-width: 100%;
    }
    .nebular-scroll::-webkit-scrollbar {
        width: 0.3125rem;
        height: 0.3125rem;
      }
      
      .nebular-scroll::-webkit-scrollbar-thumb {
        background: nb-theme(background-basic-color-4);
        cursor: pointer;
        border-radius: 0.15625rem;
      }
      
      .nebular-scroll::-webkit-scrollbar-track {
        background: transparent;
      }
    .page-header {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: nb-theme(color-light-background);
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        position: absolute;
        top: 0;
        z-index: 1000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        width: 100%;
        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */ 
            height: 50px;
        }
        @supports not (-webkit-touch-callout: none) {
            /* CSS for other than iOS devices */ 
            height: 60px;
        }
        nb-icon {
            min-width: 40px;
            text-align: center;
            cursor: pointer;
            color: nb-theme(nav-grey-color)
        }
        .back-button {
            cursor: pointer;
            font-size: 32px;
            color: nb-theme(basic-text-color);
        }
        .header-title {
            font-family: nb-theme(font-family-primary);
            font-weight: bold;
            font-size: 17px;
            line-height: 22px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: calc(100vw - 120px);
            color: nb-theme(basic-text-color);
        }
    }
    .page-content {
        @extend .nebular-scroll;
        padding-left: .625rem;
        padding-right: .625rem;
        padding-top: 1rem;
        max-height: calc(100vh - 135px);
        min-height: calc(100vh - 135px);
        overflow-y: auto;
        width: 100%;
        position: absolute;
        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */ 
            top: 50px;
        }
        @supports not (-webkit-touch-callout: none) {
            /* CSS for other than iOS devices */ 
            top: 60px;
        }
    }

    .model-full {
        $model-full-top-offset: 0;
        .page-header {
            position: relative;
            width: 100vw;
            top: $model-full-top-offset;
        }
        .page-content {
            position: relative;
            top: $model-full-top-offset;
            background-color: nb-theme(layout-background-color);
            max-height: calc(100vh - 23px);
            min-height: calc(100vh - 23px);
        }
    }

    .page-content.no-bottom-nav {
        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */ 
            max-height: calc(100vh - 50px) !important;
            min-height: calc(100vh - 50px) !important;
        }
        @supports not (-webkit-touch-callout: none) {
            /* CSS for other than iOS devices */ 
            max-height: calc(100vh - 60px) !important;
            min-height: calc(100vh - 60px) !important;
        }
    }

    .page-footer {
        height: 75px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 20px;
        padding-bottom: 25px;
        background-color: nb-theme(color-light-background);
        border-top: nb-theme(default-border);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1000;
        width: 100%;
    }

    .tox .tox-dialog-wrap__backdrop, .tox .tox-dialog-wrap__backdrop--opaque {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }

    .fab {
        background-color: nb-theme(color-primary-500);
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: none;
        outline: none;
        color: #fff;
        font-size: 18px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tag-chip {
        border: none;
        background-color: nb-theme(rack-blue);
        color: #FFFFFF;
        margin: 10px 5px 0px 0px;
        font-family: 'Archivo';
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        border-radius: 20px;
        padding: 5px 10px 5px 10px;
    }

    .progress-bar-wrapper {
        width: 100%;
        nb-progress-bar {
            .progress-container {
                height: 10px !important;
            }
            .progress-value {
                border-radius: 0.625rem;
            }
        }
        .title-wrapper {
            display: flex;
            justify-content: space-between;
            .detail {
                font-family: nb-theme(font-family-primary);
                font-weight: 500;
                font-size: 13px;
                color: nb-theme(basic-text-color);
                margin-bottom: 5px;
            }
        }
        label {
            font-family: nb-theme(font-family-primary);
            font-weight: 700;
            font-size: 13px;
            color: nb-theme(basic-text-color)
        }
        .percentage {
            margin-top: 5px;
            font-family: 'Archivo Narrow';
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color: #8B8B8B;
        }
    }

    .custom-tabs {
        overflow-x: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        nb-tabset .tabset {
        border-bottom: none;
        padding: 0px 15px 0px 10px;
        min-width: max-content;
        }
        nb-tabset .tabset .tab {
        margin-top: 3px;
        margin-bottom: 3px !important;
        }
        nb-tabset .tab.active .tab-link {
        background-color: nb-theme(rack-blue);
        color: white;
        border-radius: 10px;
        }
        nb-tabset .tab.active:hover .tab-link {
        background-color: nb-theme(rack-blue);
        color: white;
        }
        nb-tabset .tab .tab-link {
        padding: 15px 20px 15px 20px;
        margin-right: 5px;
            .tab-text {
                text-transform: none;
                font-family: 'Archivo';
            }
        }
        nb-tabset .tab.active .tab-link {
        outline: none;
        }
        nb-tabset .tab:active .tab-link {
        outline: none;
        }
        nb-tabset .tab.active .tab-link::before {
        background-color: transparent;
        }
        nb-tabset .tab:hover .tab-link::before {
        background-color: transparent;
        }
        nb-tabset .tab:hover .tab-link {
        color: nb-theme(rack-blue);
        background-color: #E4E9F2;
        border-radius: 10px;
        }
    }
    .custom-tabs::-webkit-scrollbar { 
    width: 0;  /* Safari and Chrome */
    height: 0;
    }

    .rack-info {
        border: 1px solid rgb(235, 186, 26);
        border-radius: 10px;
        padding: 10px 20px;
        background-color: rgb(235, 186, 26, 15%);
        color: rgb(235, 186, 26);
    }
    
    nb-auth nb-card {
        height: 100% !important;
        nb-card-body {
            position: inherit !important;
        }
    }
    nb-card {
        box-shadow: 0px 4px 15px 0px #0000001a;
    }
    nb-card-body.no-vertical-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
    nb-card-header {
        border-bottom: 1px solid nb-theme(divider-color-alternate) !important;
    }
    nb-card-footer {
        border-top: 1px solid nb-theme(divider-color-alternate) !important;
    }
    .space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .header-text-button {
        font-family: Archivo;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        color: nb-theme(color-primary-500);
        text-transform: uppercase;
    }
    nb-auth .columns > nb-layout-column > nb-card > nb-card-header {
        display: none !important;
    }
    nb-auth .columns>nb-layout-column>nb-card>nb-card-body {
        padding: 0 !important;
        nb-auth-block {
            margin: 0 !important;
            max-width: none !important;
        }
    }
    .cursor-pointer {
        cursor: pointer;
    }
     ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
     ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
     ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
        display: none;
    }
    .flex-center {
        display: flex;
        align-items: center;
    }
    nb-select.list-select>.select-button {
        nb-select-label {
            nb-icon {
                display: inline-block !important;
            }
            font-size: 16px;
            font-weight: 600;
        }
        nb-icon {
            display: none;
        }
        border: none;
        background-color: transparent;
        padding: 10px 0 !important;
    }
    .uppercase {
        text-transform: uppercase;
    }
    // Input Field Placeholder Text
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    :-ms-input-placeholder { /* IE 10+ */
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    :-moz-placeholder { /* Firefox 18- */
        font-size: 14px !important;
        font-weight: 600 !important;
    }
    .bottom-modal {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        padding-bottom: 25px;
        border-radius: 10px 10px 0 0;
    }
    .blue-button {
        background-color: #0085FF !important;
        color: #fff !important;
        border: none !important;
    }
    .blue-button:disabled {
        background-color: #6ea8df !important;
    }

    .relative-scroll {
        .scrollable-container {
            position: relative;
        }
    }

    .scrollable-container {
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }

    nb-layout .layout .layout-container .content .columns nb-layout-column {
        padding: 0 !important;
    }

    .spinner-container {
        height: 50px;
    }

    .pace {
        display: none !important;
    }

    .calendar-placeholder {
        position: absolute;
        right: 30px;
        margin-top: 15px;
        color: nb-theme(placeholder-text-color);
        pointer-events: none;
    }

    .timer-progress {
        .progress-container {
            background-color: #E4E9F2 !important;
            border-radius: 100px;
            width: 100%;
            height: 11px !important;
            .progress-value {
                background: linear-gradient(90deg, #20A632 0%, #5FE870 100%);
                border-radius: 100px;
            }
        }
      }

    nb-toast {
        width: 100% !important;
    }

    .open-url {
        cursor: pointer;
        color: nb-theme(rack-blue) !important;
    }

    .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
        background-color: nb-theme(rack-blue) !important;
    }

    .mat-menu-panel {
        background-color: nb-theme(color-light-background) !important;
        color: nb-theme(menu-text-color) !important;
        button, .mat-menu-ripple {
            border: none !important;
            outline: none !important;
            color: nb-theme(menu-text-color) !important;
        }
    }
}
