@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import '~@mdi/font/css/materialdesignicons.css';

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

};

.color-primary {
  color:  #0085FF;
}

// Custom Tailwindcss
.color-green {
  color: nb-theme(rack-green) !important;
}
.color-red {
  color: nb-theme(rack-red) !important;
}
.color-grey {
  color: #bcbec0;
}

.archivo-narrow-700 {
  font-family: 'Archivo Narrow', 'Open Sans';
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.archivo-narrow-bold {
  font-family: 'Archivo Narrow', 'Open Sans';
  font-weight: bold;
  font-size: 13px;
}

.page-heading {
  font-family: 'Archivo Black', 'Open Sans';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 0.67em;
  padding-top: 0;
}

.label-with-link {
  display: flex;
  justify-content: space-between;

  div, a {
    font-family: var(--font-body, Archivo, "Open Sans");
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
    color: rgba(143, 155, 179, 0.48);
  }
}

.subheading {
  color: #A6B2BE;
}

.select-clear {
  position: absolute;
  margin-left: -48px;
  margin-top: 10px;
  cursor: pointer;
  z-index: 10;
}

input[fullWidth] {
  width: 100%;
  max-width: none !important;
}

nb-sidebar {
  .scrollable {
    padding-top: 0 !important;
  }
}

nb-menu.narrow {
  span {
    font-family: 'Archivo Narrow', Archivo, 'Open Sans' !important;
    font-size: 16px !important;
    nb-icon {
      height: 24px;
      width: 24px;
    }
  }
  li a {
    padding: 0.95rem 1rem !important;
  }
}

nb-tabset .tab.active .tab-link::before {
  background-color: transparent !important;
}

nb-menu.horizontal {
  ul {
    display: flex !important;
    li {
      border-bottom: none !important;
    }
  }
}

nb-progress-bar.tiny {
  .progress-container {
      height: 6px !important;
  }
}

nb-progress-bar.dark-grey {
  .progress-container {
      background-color: #e2e2e2 !important;
  }
}

nb-layout .layout .layout-container .content .columns nb-layout-column.no-padding {
  padding: 0 !important;
}

a {
  text-decoration: none !important;
}

.html-content {
  .paragraph, ul, li, p {
    color: inherit !important;
  }
}

div.gray {
  input {
    color: gray !important;
  }
}

.tab-link {
  font-family: 'Archivo Narrow', 'Open Sans' !important;
}

nb-tabset .tab:hover .tab-link::before {
  background-color: transparent !important;
}

blockquote {
  border-left: 2px solid #ccc;
  margin-left: 1rem;
  padding-left: 1rem;
}

.padded-container {
  padding: 35px;
}

.content-entry-box {
  border: 1px solid #e4e9f2;
  border-radius: 0.25rem;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #DBDFE4;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

nb-tag-list.borderless {
  border: none !important;
  background-color: transparent !important;
}

nb-tag {
  font-family: 'Archivo', 'Open Sans' !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}

nb-tag.secondary {
  background-color: #E9F0F5 !important;
}

nb-tag.primary {
  color: white !important;
  background-color: #0085FF !important;
}

nb-tabset a.tab-link {
  align-items: center !important;
}

.freeform-container {
  p, div, span {
      line-height: normal !important;
  }
}

// Social Post Attached Content
.social-post-attached-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  .content-item {
      display: flex;
      align-items: center;
      padding: 7px 10px;
      width: max-content;
      max-width: 260px;
      background-color: nb-theme(nav-background);
      border: nb-theme(default-border);
      border-radius: nb-theme(default-border-radius);
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      span.glyphter-barbell::before, nb-icon[icon="clipboard-outline"], nb-icon[icon="dumbbell"] {
        font-size: 16px;
        color: nb-theme(rack-blue);
        margin-right: 10px;
      }
      nb-icon[icon="dumbbell"] {
        transform: translateY(-0.5px);
      }
      span.glyphter-barbell::before {
        transform: translateY(2.5px);
      }
      .content-title {
          @include text-ellipsis(1);
          font-family: nb-theme(font-family-primary);
          font-weight: 700;
          font-size: 13px;
          color: nb-theme(basic-text-color);
          margin-right: 5px;
      }
      nb-icon[icon="cloud-download-outline"], nb-icon[icon="close-circle-outline"] {
          font-size: 17px;
          color: nb-theme(nav-grey-color);
          margin-left: 10px;
          min-width: 17px;
          &:hover {
              color: nb-theme(rack-blue);
          }
      }
  }
  .attached-image {
    background-color: nb-theme(nav-background);
    border: nb-theme(default-border);
    border-radius: nb-theme(default-border-radius);
    padding: 7px;
    margin-right: 10px;
    margin-bottom: 10px;
    img {
      height: 75px;
      width: 75px;
      cursor: pointer;
    }
    .icon-wrapper {
      text-align: center;
      padding-top: 7px;
      nb-icon[icon="close-circle-outline"] {
        color: nb-theme(nav-grey-color);
        cursor: pointer;
        &:hover {
          color: nb-theme(rack-blue);
        }
      }
    }
  }
  .image-preview-grid {
    display: grid;
    width: 100%;
    cursor: pointer;
    .image {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .more-images-message {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.70);
        color: nb-theme(basic-placeholder-text-color);
        font-family: nb-theme(font-family-primary);
        font-size: 15px;
      }
    }
  }
}

// Chat
app-chat-message {
  .text, .sender {
    width: max-content;
    max-width: calc(100vw - 90px);
  }
  .sender {
    font-family: nb-theme(font-family-secondary);
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .text {
    font-family: nb-theme(font-family-primary);
    font-weight: 500;
    font-size: 14px;
    border-radius: nb-theme(default-border-radius);
    padding: 15px;
  }

  &.not-reply {
    .text {
      background: nb-theme(comment-background-color);
      color: nb-theme(basic-text-color);
    }
  }
  &.reply {
    .text, .sender {
      margin-left: auto;
    }
    .text {
      background: nb-theme(rack-blue);
      color: #fff;
    }
  }

}

app-chat .form nb-chat-form {
  width: 100%;
  padding-right: 10px;
  padding-left: 0 !important;
  border-top: none !important;
  .message-row input {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background-color: nb-theme(comment-background-color);
    color: nb-theme(basic-text-color);
    border: nb-theme(default-border);
    font-family: nb-theme(font-family-primary);
    font-weight: 400;
    font-size: 14px;
    &:hover, &:focus {
      background-color: nb-theme(comment-background-color);
      border: nb-theme(default-border);
    }
    &::placeholder {
      color: nb-theme(basic-placeholder-text-color);
    }
  }
  .send-button {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}
nb-chat-form .send-button {
  background-color: #0085ff !important;
  border: nb-theme(default-border);
  border-left: none;
}

// Zoom Control
.zoom-control {
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  // background-color: rgba(255, 255, 255, 0.95);
  background-color: nb-theme(background-basic-color-4);
  opacity: 0.95;
  border-bottom-right-radius: nb-theme(default-border-radius);
  nb-icon[icon="plus-outline"], nb-icon[icon="minus-outline"] {
      color: nb-theme(nav-grey-color);
      background-color: nb-theme(basic-placeholder-text-color);
      border-radius: 5px;
      padding: 2px;
  }
  nb-icon[icon="refresh-outline"] {
      color: nb-theme(nav-grey-color);
  }
  nb-icon {
      margin-bottom: 10px;
      &.disabled {
          background-color: #D6D9DD !important;
      }
  }
  .percentage {
      user-select: none;
      font-family: nb-theme(font-family-secondary);
      font-weight: 700;
      font-size: 11px;
      color: nb-theme(nav-grey-color);
  }
}

.post-editor-actions {
  display: flex;
  justify-content: center;
  align-items: center;

  // fix misaligned nb tooltips
  span {
      width: 16px;
      margin-right: 15px;
  }

  nb-icon, span::before {
      margin-right: 15px;
      color: nb-theme(nav-grey-color);
      cursor: pointer;
  }
  nb-icon:hover, span:hover::before {
      color: nb-theme(rack-blue);
  }

  span::before {
      display: flex;
      font-size: 16px;
  }

  .upload-img-btn {
      display: flex;
      position: relative;
      input {
          opacity: 0;
          cursor: pointer;
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          top: 0;
          left: -6px;
      }
      &:hover {
          nb-icon {
              color: nb-theme(rack-blue);
          }
      }
  }
}